@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@300;400;500;700&display=swap');

footer {
  background-color: #280331 !important;
}

.main ul {
  list-style: disc;
  margin: 15px 0;
  padding: 0 0 0 25px;
}

.section_content li {
  padding-left: 20px;
  line-height: 22px;
  text-indent: -16px;
  text-align: left;
}

.section_content li b {
  margin-left: -5px;
}

.section_content {
  color: #1C002C !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Urbanist" !important;
}

.App-hero .emphasis_para {
  font-weight: 600 !important;
  margin-top: 2rem;
}

.App-hero {
  background-image: url('../public/1920x1080-ailog-header-new.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
}

.cardItem div[data-testid=flowbite-card] {
  height: 280px;
  border-radius: 8px;
  border: 1px solid #BECAD6;
  background: linear-gradient(0deg, #FFF 0%, #FFF 100%), linear-gradient(0deg, #1C002C 0%, #1C002C 100%), linear-gradient(0deg, #F9FBFF 0%, #F9FBFF 100%), #F2F8FD;
}

.cardItem div[data-testid=flowbite-card]:hover {
  border: 1px solid #1163FC;
  filter: drop-shadow(0px 5px 15px rgba(190, 202, 214, 0.40));
  background: linear-gradient(0deg, #FFF 0%, #FFF 100%), linear-gradient(0deg, #1C002C 0%, #1C002C 100%), linear-gradient(0deg, #F9FBFF 0%, #F9FBFF 100%), #F2F8FD;
}

.cardItem div[data-testid=flowbite-card]:hover path {
  fill: #1163FC;
}

.cardItem div[data-testid=flowbite-card] div {
  justify-content: normal;
}

.bg-cover.bg-no-repeat {
  background-color: transparent !important;
}

nav a {
  width: 100%;
  margin: 0 auto;
  display: block;
  text-align: center;
}

nav a img {
  margin: 20px auto;
}

footer .grid, footer .divider {
  display: none !important;
}

@media screen and (max-width:767px){
  .App-hero {
    background-position: center;
  }
}